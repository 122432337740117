<template>
    <div v-if="invoiceData.user_data" class="grid grid-cols-4 gap-2 text-sm ">
        <div class="bg-gray-50 border-gray-200 border p-4 rounded-lg">
            <p class="text-xs font-semibold mb-1">
                {{ $t('economy.address') }}
            </p>
            <p>{{ invoiceData.user_data.street }}</p>
            <p>{{ invoiceData.user_data.post_code }} {{ invoiceData.user_data.city }}</p>
        </div>
        <div class="bg-gray-50 border-gray-200 border p-4 rounded-lg">
            <p class="text-xs font-semibold mb-1">
                {{ $t('economy.information') }}
            </p>
            <p>{{ invoiceData.user_data.company_name }}</p>
            <p>Org nr: {{ invoiceData.user_data.orgnummer }}</p>
            <p>Godkänd för F-skatt</p>
        </div>
        <div class="bg-gray-50 border-gray-200 border p-4 rounded-lg">
            <p class="text-xs font-semibold mb-1">
                {{ $t('economy.bank') }}
            </p>
            <p>{{ invoiceData.bank_account_name }}</p>
            <p>{{ invoiceData.bank_account_nr }}</p>
        </div>
        <div class="bg-gray-50 border-gray-200 border p-4 rounded-lg">
            <p class="text-xs font-semibold mb-1">
                {{ $t('economy.contact') }}
            </p>
            <p v-if="invoiceData.user_data.telephone">
                Tel: {{ invoiceData.user_data.telephone }}
            </p>
            <p>{{ invoiceData.user_data.email }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        invoiceData: {
            type:    Object,
            default: () => {},
        },
    },
};
</script>
